import { compact } from '@leland-dev/leland-ui-library';
import { type ParsedUrlQueryInput } from 'querystring';
import { type UrlObject } from 'url';

function omitNil<T extends Record<string, unknown>>(
  obj: T,
): NonNullable<Partial<T>> {
  return Object.entries(obj).reduce((newObj, [key, value]) => {
    if (value != null) {
      newObj[key] = value;
    }
    return newObj;
  }, {});
}

export const getUrlString = (
  url: string,
  queryParams: Optional<Partial<ParsedUrlQueryInput>>,
  useHash?: boolean,
): string => {
  const paramsString = Object.entries(omitNil(queryParams ?? {}))
    .map(([key, value]) => {
      return (
        (Array.isArray(value) ? value : [value])
          .filter(compact)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join('&')
      );
    })
    .join('&');

  const char = useHash ? '#' : '?';
  return `${url}${paramsString.length ? `${url.includes(char) ? '&' : char}${paramsString}` : ''}`;
};

export const getUrlObject = (
  pathname: string,
  query: Optional<Partial<ParsedUrlQueryInput>>,
): UrlObject => ({
  // trim query params from pathname
  pathname: pathname.replace(/[?#].*/, ''),
  ...(query ? { query: omitNil(query) } : {}),
});
