import {
  BrandFacebookSilhouette,
  BrandLelandLogoSilhouette,
  BrandLinkedinSilhouette,
  BrandTwitterSilhouette,
} from '@leland-dev/leland-ui-library';
import Link from 'next/link';
import { type FC } from 'react';

import {
  APPLICANT_LOGIN_URL,
  APPLICANT_SIGNUP_URL,
  LIBRARY_BASE_URL,
  PARTNERSHIPS_LP_URL,
} from '../utils/constants';
import { getLibraryPageUrl } from '../utils/routing';

const MainFooter: FC = () => {
  const navLayers = [
    {
      title: 'Welcome',
      links: [
        {
          title: 'Get started',
          url: APPLICANT_SIGNUP_URL,
        },
        {
          title: 'Log in',
          url: APPLICANT_LOGIN_URL,
        },
        {
          title: 'Become a coach',
          url: '/become-a-coach',
        },
        {
          label: 'Free events',
          url: '/events',
        },
      ],
    },
    {
      title: 'Library',
      links: [
        {
          title: 'Content Library',
          url: LIBRARY_BASE_URL,
        },
        {
          title: 'MBA',
          url: getLibraryPageUrl({ slug: 'mba' }),
        },
        {
          title: 'College',
          url: getLibraryPageUrl({ slug: 'college' }),
        },
        {
          title: 'Management Consulting',
          url: getLibraryPageUrl({ slug: 'management-consulting' }),
        },
        {
          title: 'Product Management',
          url: getLibraryPageUrl({ slug: 'product-management' }),
        },
      ],
    },
    {
      title: 'Company',
      links: [
        {
          title: 'Careers',
          url: '/careers',
        },
        {
          title: 'Partnerships',
          url: PARTNERSHIPS_LP_URL,
          leavesSite: true,
        },
        {
          title: 'Terms of Service',
          url: '/legal/terms',
        },
        {
          title: 'Privacy Policy',
          url: '/legal/privacy-policy',
        },
        {
          title: 'Support',
          url: '/support',
        },
      ],
    },
  ];
  return (
    <footer className="w-full bg-leland-gray-dark px-4 pb-20 pt-12 lg:px-8">
      <div className="mx-auto grid max-w-screen-xl grid-cols-12 gap-3">
        <div className="col-span-12 mb-8 lg:col-span-3">
          <Link
            href="/"
            className="mb-6 inline-block text-white lg:hover:opacity-50"
          >
            <BrandLelandLogoSilhouette className="w-32" />
          </Link>

          <div className="mb-9 flex text-white">
            <a
              href="https://www.facebook.com/joinleland"
              target="_blank"
              className="mr-6 opacity-70 lg:hover:opacity-30"
              rel="noreferrer"
            >
              <BrandFacebookSilhouette className="w-6" />
            </a>

            <a
              href="https://twitter.com/joinLeland"
              target="_blank"
              className="mr-6 opacity-70 lg:hover:opacity-30"
              rel="noreferrer"
            >
              <BrandTwitterSilhouette className="w-6" />
            </a>

            <a
              href="https://www.linkedin.com/company/joinleland"
              target="_blank"
              className="mr-6 opacity-70 lg:hover:opacity-30"
              rel="noreferrer"
            >
              <BrandLinkedinSilhouette className="w-6" />
            </a>
          </div>

          <p className="text-sm text-white/70">
            © Leland {new Date().getFullYear()}. All rights reserved.
          </p>
        </div>

        {navLayers.map((item, index) => (
          <div className="col-span-12 mb-8 lg:col-span-3" key={index}>
            <p className="mb-1 text-lg text-white">{item.title}</p>
            <ul>
              {item.links.map(
                (
                  link: { title: string; url: string; leavesSite?: boolean },
                  idx: number,
                ) => (
                  <li className="mb-1" key={idx}>
                    {link.leavesSite ? (
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-lg text-white/60 lg:hover:underline"
                      >
                        {link.title}
                      </a>
                    ) : (
                      <Link
                        href={link.url}
                        className="text-lg text-white/60 lg:hover:underline"
                      >
                        {link.title}
                      </Link>
                    )}
                  </li>
                ),
              )}
            </ul>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default MainFooter;
