import {
  IconArrowRight,
  IconX,
  isDateAfter,
} from '@leland-dev/leland-ui-library';
import { type FC, type ReactElement, useState } from 'react';

interface Props {
  href: string;
  text: string | ReactElement;
  emoji?: string;
  dismissable?: boolean;
  until?: number; // Date.getTime()
}

const AnnouncementBanner: FC<Props> = ({
  href,
  text,
  emoji = '🎉',
  dismissable,
  until,
}) => {
  const [showAnnouncement, setShowAnnouncement] = useState(true);

  if (!showAnnouncement || (until && isDateAfter(Date.now(), until))) {
    return null;
  }

  return (
    <div className="announcement-banner relative w-full lg:hover:opacity-90">
      <a
        className="relative flex size-full items-center justify-center bg-leland-dark-green px-12 text-center lg:px-4"
        href={href}
      >
        <div className="items-center text-lg text-white sm:flex">
          <p className="mr-3 inline">
            <span className="mr-3">{emoji}</span>
            {text}
          </p>
          <IconArrowRight className="inline size-4" />
        </div>
      </a>

      {dismissable ? (
        <button
          onClick={() => setShowAnnouncement(false)}
          className="absolute right-0 top-0 flex h-full items-center justify-end px-6 text-white"
        >
          <IconX className="size-4" />
        </button>
      ) : null}
    </div>
  );
};

export default AnnouncementBanner;
