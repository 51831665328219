import {
  Breakpoints,
  compact,
  type HeaderButton,
  HeaderEntryTextColor,
  IconGraduateHat,
  IconInbox,
  IconProfile,
  IconQuestion,
  IconSignOut,
  IconSwitch,
  IconWarningTriangle,
  type MobileNavEntry,
  type NavProps,
  NavTheme,
  PageHeader,
  type SubNavProps,
  useMediaQuery,
} from '@leland-dev/leland-ui-library';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { type FC, useMemo } from 'react';

import { useAuth } from '../context/AuthContext';
import { LexSlug, useLex } from '../context/LexContext';
import {
  APPLICANT_SIGNUP_URL,
  APPLICANT_SUBSCRIBER_DASHBOARD_URL,
  COACH_BASE_URL,
  LELAND_PLUS_REDIRECT_URL,
  PARTNERSHIPS_LP_URL,
  SUPPORT_URL,
} from '../utils/constants';
import { LelandImpersonation } from '../utils/impersonation';
import { generateSearchPath } from '../utils/routing';

import {
  type LulNavbarNavCategoryListingFragment,
  useLulNavbarUserQuery,
} from './__generated-gql-types__/LulNavbar.generated';

const InboxIconWithUnreadIndicator = dynamic(
  () =>
    import('./nav/InboxIconWithUnreadIndicator').then(
      (mod) => mod.InboxIconWithUnreadIndicator,
    ),
  { ssr: false },
);

export interface LulNavbarProps {
  hideSubnav?: boolean;
  pageContentData: Possible<{
    navCategoryListings: LulNavbarNavCategoryListingFragment[];
  }>;
}

export const LulNavbar: FC<LulNavbarProps> = ({ pageContentData }) => {
  const router = useRouter();
  const {
    isNewSubscriptionExperience: isNewSubscriptionExperienceUser,
    isImpersonating,
  } = useAuth();
  const { isLexEnabled } = useLex();
  const { data: userData, loading: userLoading } = useLulNavbarUserQuery();
  const isSm = useMediaQuery(Breakpoints.SM);
  const isMd = useMediaQuery(Breakpoints.MD);
  const isLg = useMediaQuery(Breakpoints.LG);

  const isNewSubscriptionExperience =
    isNewSubscriptionExperienceUser ||
    isLexEnabled(LexSlug.PACKAGE_HOURLY_UNIFICATION);

  const isApplicant = !!userData?.user?.applicant;
  const isCoach = !!userData?.user?.coach;

  const IMPERSONATION_HEADER_ENTRY: HeaderButton = useMemo(
    () => ({
      label: 'EXIT IMPERSONATION',
      icon: IconWarningTriangle,
      textColor: HeaderEntryTextColor.RED,
      onClick: () => {
        LelandImpersonation.removeImpersonatedUserId();

        void router.push(`${COACH_BASE_URL}/internal/ops/customers`);
      },
    }),
    [router],
  );

  const navCategories: NavProps['categories'] = userData?.user
    ? pageContentData?.navCategoryListings?.map(
        ({ name, goal, categories }) => {
          return {
            url: goal
              ? generateSearchPath({
                  goal,
                  categorySlug: null,
                  subCategorySlug: null,
                })
              : undefined,
            label: name,
            subItems: categories?.map(
              ({
                name,
                goal,
                slug,
                subCategoryList,
                shouldShowSubCategories,
              }) => {
                return {
                  url: generateSearchPath({
                    goal,
                    categorySlug: slug,
                    subCategorySlug: null,
                  }),
                  label: name,
                  subItems: shouldShowSubCategories
                    ? subCategoryList?.map(
                        ({ name, slug: subCategorySlug }) => {
                          return {
                            url: generateSearchPath({
                              goal,
                              categorySlug: slug,
                              subCategorySlug,
                            }),
                            label: name,
                          };
                        },
                      )
                    : undefined,
                };
              },
            ),
          };
        },
      )
    : undefined;

  const profileMenuItemSections: MobileNavEntry[][] = useMemo(() => {
    const sections: Array<Nullable<Array<Nullable<MobileNavEntry>>>> = [
      [isImpersonating ? IMPERSONATION_HEADER_ENTRY : null],
      [
        {
          url: isApplicant ? '/settings' : COACH_BASE_URL,
          LeftIcon: IconProfile,
          label: 'Profile',
        },
        isCoach
          ? {
              url: COACH_BASE_URL,
              LeftIcon: IconSwitch,
              label: 'Switch to Coaching',
            }
          : {
              url: `/become-a-coach`,
              LeftIcon: IconGraduateHat,
              label: 'Become a Coach',
            },
      ],
      [
        {
          url: SUPPORT_URL,
          LeftIcon: IconQuestion,
          label: 'Help & Support',
        },
        {
          url: '/logout',
          LeftIcon: IconSignOut,
          label: 'Log Out',
        },
      ],
    ];
    return sections.reduce<MobileNavEntry[][]>(
      (reducedSections, currentSection) => {
        const filteredCurrentSection = currentSection?.filter(compact);
        if (filteredCurrentSection?.length) {
          return [...reducedSections, filteredCurrentSection];
        }
        return reducedSections;
      },
      [],
    );
  }, [IMPERSONATION_HEADER_ENTRY, isImpersonating, isApplicant, isCoach]);

  const leftLinks: NavProps['leftLinks'] =
    isSm && isImpersonating ? [IMPERSONATION_HEADER_ENTRY] : undefined;

  const isActiveLelandPlus = userData?.user?.applicant?.lelandPlus?.active;

  const rightLinks: NavProps['rightLinks'] = useMemo(() => {
    const links: NavProps['rightLinks'] = [];
    if (isSm) {
      if (isApplicant) {
        if (isNewSubscriptionExperience) {
          links.push({
            label: 'Dashboard',
            url: APPLICANT_SUBSCRIBER_DASHBOARD_URL,
          });
        }
        if (isActiveLelandPlus) {
          links.push({
            label: 'Leland+',
            url: LELAND_PLUS_REDIRECT_URL,
            isExternal: true,
          });
        }
      }
      links.push({
        label: 'Free events',
        url: '/events',
        isExternal: false,
      });
    }

    if (isApplicant) {
      links.push({
        label: 'Inbox',
        CustomLeftIcon: InboxIconWithUnreadIndicator as FC<{
          iconClassName?: string;
        }>,
        LeftIcon: IconInbox,
        url: `/inbox`,
        hideLabel: true,
        rounded: true,
      });
    } else if (isSm) {
      links.push({
        label: 'Reviews',
        url: '/reviews',
      });
      if (isMd) {
        links.push({
          label: 'Become a coach',
          url: `/become-a-coach`,
        });
      }
      if (isLg) {
        links.push({
          label: 'Partnerships',
          url: PARTNERSHIPS_LP_URL,
        });
      }
    } else {
      links.push({
        label: 'Join',
        url: APPLICANT_SIGNUP_URL,
      });
    }
    return links;
  }, [
    isActiveLelandPlus,
    isApplicant,
    isSm,
    isMd,
    isLg,
    isNewSubscriptionExperience,
  ]);

  const mobileSidebarLinks: MobileNavEntry[] = useMemo(() => {
    return (
      [
        isImpersonating ? IMPERSONATION_HEADER_ENTRY : null,
        isNewSubscriptionExperience
          ? {
              label: 'Dashboard',
              url: APPLICANT_SUBSCRIBER_DASHBOARD_URL,
            }
          : null,
        {
          label: 'Inbox',
          url: '/inbox',
        },
        {
          label: 'Free events',
          url: '/events',
        },
        userData?.user?.applicant?.lelandPlus?.active
          ? {
              label: 'Leland+',
              url: LELAND_PLUS_REDIRECT_URL,
              isExternal: true,
            }
          : null,
      ] satisfies Array<Nullable<MobileNavEntry>>
    ).filter(compact);
  }, [
    IMPERSONATION_HEADER_ENTRY,
    isImpersonating,
    isNewSubscriptionExperience,
    userData?.user?.applicant?.lelandPlus?.active,
  ]);

  const accountLinks: MobileNavEntry[] = useMemo(() => {
    return userData
      ? [
          {
            label: 'Profile',
            url: userData?.user?.applicant ? '/settings' : COACH_BASE_URL,
          },
          {
            label: 'Order History',
            url: '/settings/order-history',
          },
          {
            label: 'Payment Details',
            url: '/settings/payment-details',
          },
          {
            label: 'Log out',
            url: '/logout',
            textColor: HeaderEntryTextColor.RED,
          },
        ]
      : [];
  }, [userData]);

  const navProps: NavProps = {
    categories: navCategories,
    theme: isImpersonating ? NavTheme.RED : undefined,
    urls: {
      home: '/',
      login: '/login',
      signup: APPLICANT_SIGNUP_URL,
      inbox: '/inbox',
    },
    rightLinks,
    leftLinks,
    mobileLinks: {
      sidebar: mobileSidebarLinks,
      footer: [
        {
          label: 'Home',
          url: '/',
        },
        {
          label: 'Become a coach',
          url: `/become-a-coach`,
        },
        {
          label: 'Partnerships',
          url: PARTNERSHIPS_LP_URL,
          isExternal: true,
        },
        {
          label: 'Support',
          url: SUPPORT_URL,
        },
      ],
      browseCoaching: navCategories,
      accountMenu: accountLinks,
    },
    profileMenuItemSections,
    user: userData?.user
      ? {
          ...userData.user,
          profileLink: userData.user.applicant ? '/settings' : COACH_BASE_URL,
        }
      : undefined,
    userLoading,
  };

  // TODO: Add subnav props once we have pages with subnavs
  const subNavProps: Optional<SubNavProps> = undefined;

  return <PageHeader navProps={navProps} subNavProps={subNavProps} />;
};
